<template>
   <div class="main">
      <nav class="navbar navbar-light">
        <div class="container-fluid col-12">
            <router-link :to="'/store/' + userId"><div class="navbar-brand"><img src="@/assets/logo.png" alt="HOME" title="HOME"></div></router-link>
            
              <div class="d-flex my-2 mx-2 my-lg-0">
                <div class="dropdown">
                   <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="control">
                        <a class="button">
                         <b-icon icon="person-fill"> </b-icon>
                          {{ init.profile.fullName }}
                        </a>   
                    </span><span class="caret"></span></button>
                   <div class="dropdown-content">
                        <router-link :to="'/txhistory/' + userId"><div class="a-text">Transaction History </div></router-link>
                        <a href="#" class="a-text" v-on:click="openLogout">Log out </a>
                    </div>
                </div>
              </div>
          </div>
          </nav>

     <!-- <div id="loading" v-if="showLoading">
      <div class="content">
 
        <v-icon name="spinner" pulse scale="3" />

      </div>
    </div> -->
    <div >
      <h1 class="mt-5 text-white">My Transactions</h1>
        <div class="container mt-5">
          <div class="d-modal row">
             <div class="row container">
              <div class="d-flex justify-content-between">
                <div class="col-7 d-flex container my-5">
                  <div class="d-flex">
                     <input type="date" id="fromDate" name="currenDateTime">
                     <input type="date" id="toDate" name="currenDateTime">
                  </div>
                  <select name="status" @change="onChange($event)" v-model="init.status" class="search-wrapper form-select form-control">
                      <option>Status</option>
                      <option value="ALL">ALL</option>
                      <option value="00" >PENDING</option>
                      <option value="11" >SUCCESS</option>
                      <option value="99" >FAILED</option>
                  </select>
                  <button class="btn btn-secondary" type="button" @click="getHistory()">GO</button>

                </div>
              </div>


              <div class="container center">
                <div class="table-responsive" >
                  <table id="card">
                     
                    <thead class="thead text-left">
                              <tr >
                              <th>TIMESTAMP</th>
                              <th>TXN ID</th>
                              <th>CURRENCY BOUGHT</th> 
                              <th>AMOUNT</th> 
                              <th>STATUS</th>
                              </tr> 
                        
                      </thead>
                      <tbody >
                        <tr v-for="list in history" :key="list.id" >
                          <td>{{formatDate(list.txnDate)}}</td>
                          <td>{{list.txnId}}</td>
                          <td>{{list.targetCurrency}}</td> 
                          <td>{{list.targetAmount}}</td> 
                          <td>{{list.txnStatus}}</td>
                      </tr>
                      </tbody> 
                      
                </table> 
                 <table v-if="length == 0">
                        <thead  class="no-txn text-center">No transactions found!</thead>
                        <thead  class="no-txn text-center">Please enter date and status.</thead>
                </table> 
                
                </div>
            
              </div>

             </div>
          </div>
        </div>
    </div>  

  
    <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
  </div>
</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral'
  import moment from 'moment';
  import LogoutModal from '../components/modals/LogoutModal.vue'

  export default {
    name: 'TransactionHistory',
    components: {
      LogoutModal
    },
    data: function() {
      return  {
        showLoading: true,
        history: [],
        userId:'',
        length:'',
        showLogoutModal: false,
       

        
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
   
    methods: {
       openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      onChange(event){
            const selectedStatus = event.target.value;
            this.selectedStatus = selectedStatus
            console.log(this.selectedStatus)
            
      },


      formatDate: function(date) {
        return moment(date).format('MM/DD/yyyy hh:mm:ss A');
      },
      formatAmount: function(num){
        return numeral(num).format('0,0.00000000');
      },
      //  getCurrentDateTime: function() {
      //               const today = new Date();
      //               const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      //               const dateTime = date;
      //               this.currentDate = dateTime;
      //  },

    
     

      getHistory(){
      const params = {
       	fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
					toDate: moment(this.toDate).add(1, 'days').format('MM/DD/yyyy'),
					rowsPerPage: 50,
					page: 1,
					status: this.selectedStatus
      }

       const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   
        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
		          data,
		          url: '/cx/buycrypto/history',
		        };

            this.axios(options)
	        	.then((response) => {  
	            	console.log(response.data);
                this.history = response.data.rowsList
                console.log(response.data.rowsList)

                this.length = response.data.rowsList.length
                console.log(response.data.rowsList.length)
	        	}).catch((err) => {
	            	console.log(err.response.data)
                this.init.error.msg = err.response.data.msgCode
                

	       	 	})

      },
      defaultHistory(){
		 const params = {
       	fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
					toDate: moment(this.toDate).add(1, 'days').format('MM/DD/yyyy'),
					rowsPerPage: 50,
					page: 1,
					status: '00'
      }

       const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   
        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
		          data,
		          url: '/cx/buycrypto/history',
		        };

            this.axios(options)
	        	.then((response) => {  
	            	console.log(response.data);
                this.history = response.data.rowsList
                console.log(response.data.rowsList)

                this.length = response.data.rowsList.length
                console.log(response.data.rowsList.length)
	        	}).catch((err) => {
	            	console.log(err.response.data)
                this.init.error.msg = err.response.data.msgCode
                

	       	 	})


      },

       getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.init.profile = response.data;
               this.userId = response.data.entityId

              })
      },
     checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.getProfile();
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
                console.log(err.response.data)
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
        this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/#/";
                
            }).catch((err) => { 
              console.log(err)

            })
      }
    },

    beforeMount(){
         this.checkSession();
         this.defaultHistory();
      
    },
    mounted(){
      //this.getCurrentDateTime();
    },
    

    watch: {
      'this.selectedStatus': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.getHistory();
            }
        },
        
      
    }
  }
</script>

<style scoped>
.main{
  height: 100vh !important;
  overflow-y: auto;
}
.body{
  margin-bottom: 100px !important;
  padding-bottom: 20px;
}
.navbar{
  display: flex;
  padding: 20px 100px;
}
#card {
		border: solid 1px #707070;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) -11%, #edf0ff 0%);
    height: auto;
 }
table{
    border-collapse: collapse;
    width: 100% !important;
    background-image: linear-gradient(
    180deg,
    rgba(249, 250, 253, 1) 29%,
    rgba(235, 238, 249, 1) 59%,
    rgba(214, 220, 244, 1) 88%
  );
    color: black;
    font-size: 80%;
    border-radius: 10px !important;
    padding: 0.5em 2em !important;
    z-index: 2 !important;
}

th, td {
  padding: 0.5em 0.5em !important;
  text-align: left !important;
}
.no-txn{
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin: 0 auto;
  text-align: center;
  color: grey;
}
tr{
  border-top: 1px solid grey;
  width: 100%;
  padding: 0.5em 2em;
  text-align: left !important;
}
#card thead{
   background-image: linear-gradient(
    180deg,
    rgba(249, 250, 253, 1) 29%,
    rgba(235, 238, 249, 1) 59%,
    rgba(214, 220, 244, 1) 88%
  );
  width: 100%;
  color: black;
  font-size: 0.875rem;
  letter-spacing: 2%;
  border-radius: 20px !important;
  padding: 0.5em 2em !important;
  z-index: 2 !important;
}
table a{
  color: #c68b17;
  text-decoration: none;
  z-index: 2 !important;
}
.dropdown {
		position: relative;
		display: inline-block;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		padding: 12px 16px;
		z-index: 1;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}
	.d-modal{
    background: rgb(2,11,54);
    background-image: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
    
  }
   @media only screen and (max-device-width: 480px) {
     .col-7{
       display: block !important;
       margin: 10px !important;
     }
     .d-flex{
       display: block !important;
     }

   }
   @media (min-width: 576px) and (max-width: 767.98px) { 
    *{
        overflow-x: hidden;
    }
  }
 

</style>



